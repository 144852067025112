import {h} from 'preact'
import {useLocation} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

import './Header.scss'
import profileImg from '../assets/images/profile.png'
import profileImg2x from '../assets/images/profile@2x.png'
import profileImgSm from '../assets/images/profile_sm.png'
import profileImgSm2x from '../assets/images/profile_sm@2x.png'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import Panel from './Panel'
import {handleScroll} from '../utils/helpers'
import {ROUTE, LINK, BREAKPOINT, BLANK_LINK_PROPS} from '../utils/constants'

export default function Header() {
  const location = useLocation()
  const mobile = useMediaQuery({
    query: BREAKPOINT.mobile,
  })

  const isFaq = location.pathname === ROUTE.faq
  return (
    <header id={LINK.home} class="Header Container">
      <Panel isFaq={isFaq} />
      {!isFaq && (
        <section class="Header-Banner">
          <Row className="Header-Content">
            <Col
              className="Header-Wrapper"
              xs={12}
              sm={6}
              md={7}
              lg={6}
              lgOffset={1}
            >
              <Animate
                el="picture"
                effect={mobile ? EFFECT.bottom : EFFECT.left}
              >
                <source
                  media={BREAKPOINT.tablet}
                  srcSet={`${profileImgSm}, ${profileImgSm2x} 2x`}
                />
                <img
                  class="Header-Image"
                  width={628}
                  height={760}
                  src={profileImg}
                  srcSet={`${profileImg2x} 2x`}
                  alt="Profile"
                />
              </Animate>
            </Col>
            <Col className="Header-Block" xs={12} sm={5} md={4} lg={3}>
              <div class="Header-Extra">
                <Animate el="h1" className="Header-Title" effect={EFFECT.right}>
                  Your Next Generation Digital Resume
                </Animate>
                <Animate el="p" className="Text" effect={EFFECT.bottom}>
                  Boost your chances of landing your dream job with our cutting
                  edge video profile mapping technology.
                </Animate>
                <Animate
                  {...BLANK_LINK_PROPS}
                  el="a"
                  className="Button"
                  href={LINK.courseUrl}
                  effect={EFFECT.zoomIn}
                >
                  Start now
                </Animate>
              </div>
            </Col>
          </Row>
        </section>
      )}
    </header>
  )
}
