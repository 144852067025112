import {h, Fragment} from 'preact'
import {useState} from 'preact/hooks'
import {Swiper, SwiperSlide} from 'swiper/react'
import {useMediaQuery} from 'react-responsive'

import './About.scss'
import manImg from '../assets/images/man.svg'
import womanImg from '../assets/images/woman.svg'
import toolsImg from '../assets/images/tools.svg'
import phoneImg from '../assets/images/phone.svg'
import gentlemanImg from '../assets/images/gentleman.svg'
import chairImg from '../assets/images/chair.svg'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import {BREAKPOINT, DURATION, LINK} from '../utils/constants'

const features = [
  {
    title: 'Get access to Present.me to start creating your digital resume',
    text:
      'Once you purchase the course you will get a unique link to its contents. You will be able to complete the course on both your desktop and mobile.',
    image: manImg,
  },
  {
    title: 'Answer all the course questions as best as you can',
    text:
      'This will help us tailor-make your digital resume and put together a script for your future video pitch.',
    image: womanImg,
  },
  {
    title: 'Get the script for your video pitch generated',
    text:
      'Based on your answers to the course questions the system will piece together the script to help you record your video pitch.',
    image: toolsImg,
  },
  {
    title: 'Download teleprompter app to create video',
    text:
      'To make recording your video pitch super easy you will be given the unique username and password to the teleprompter app. The app will run the lines from your script while you are recording your video. You will be able to make final changes to your script and do as many takes as you need before picking the best one for your digital resume.',
    image: phoneImg,
  },
  {
    title: 'Get access to your digital resume on desktop & mobile',
    text:
      'Get a unique link to your beautifully presented digital resume and a video pitch. These will be available to you forever and are ready to be shared, downloaded, generated into PDFs or printed out.',
    image: gentlemanImg,
  },
  {
    title: 'Increase your chances of getting a job',
    text:
      'Stand out among other candidates with your infographic digital resume complimented by your own unique video pitch. An instant attention grabber for recruiters.',
    image: chairImg,
  },
]

const firstSwiperOptions = {
  grabCursor: true,
  preventClicksPropagation: false,
  spaceBetween: 72,
  speed: DURATION.longer,
  wrapperTag: 'ul',
  pagination: {clickable: true},
}

const secondSwiperOptions = {
  grabCursor: true,
  speed: DURATION.longer,
  wrapperTag: 'ul',
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
}

export default function About() {
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const tablet = useMediaQuery({
    query: BREAKPOINT.tablet,
  })

  return (
    <section id={LINK.about} class="About Container">
      <Row center="xs">
        <Col xs={12} sm={8} md={4} lg={3}>
          <h2 class="Title">how it works</h2>
          <Animate effect={tablet ? EFFECT.bottom : EFFECT.right}>
            <Swiper
              {...firstSwiperOptions}
              className="About-FirstSwiper"
              controller={{control: secondSwiper}}
              onSwiper={setFirstSwiper}
            >
              {features.map((feature) => (
                <SwiperSlide key={feature.title} tag="li">
                  <div class="About-SlideContent">
                    <p class="About-Subtitle Subtitle">{feature.title}</p>
                    <p class="About-Text">{feature.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Animate>
        </Col>
        <Col xs={12} sm={8} md={6} lg={5}>
          <Animate effect={tablet ? EFFECT.bottom : EFFECT.left}>
            <Swiper
              {...secondSwiperOptions}
              controller={{control: firstSwiper}}
              onSwiper={setSecondSwiper}
            >
              {features.map((feature) => (
                <SwiperSlide key={feature.title} tag="li">
                  <img
                    class="About-Image"
                    width={530}
                    height={436}
                    src={feature.image}
                    alt={feature.title}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Animate>
        </Col>
      </Row>
    </section>
  )
}
