import {h} from 'preact'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import {useMediaQuery} from 'react-responsive'
// import ReactPlayer from 'react-player'
import {BREAKPOINT} from '../utils/constants'
import mediaImg2x from '../assets/images/media@2x.jpg'

import './Media.scss'

export default function Media() {
    const tablet = useMediaQuery({
    query: BREAKPOINT.tablet,
  })

  const textEffect = tablet ? EFFECT.bottom : EFFECT.right
  return (
    <section class="Media Container">
        <Row center="xs">
        <Col xs={12} sm={10} lg={8}>
            <h2 class="Title">media</h2>
            <Animate
              el="p"
              className="Subtitle Subtitle_smCenter"
              effect={textEffect}
            >
              What people are saying about Present Me?
            </Animate>
        </Col>
        </Row>
      <Row className="Media-Row" center="xs">
        <Col xs={12} sm={8} md={5} lg={7}>
          <Animate
            className="Media-Video"
            effect={tablet ? EFFECT.zoomOut : EFFECT.left}
          >
            <a target="_blank" href="https://www.newshub.co.nz/home/lifestyle/2021/02/the-new-age-cv-shaking-up-employment.html">
              <img src={mediaImg2x} />
            </a>
            {/* <ReactPlayer
              width="100%"
              height="100%"
              controls
              url="https://ssaimanifest.prod.boltdns.net/ap-southeast-2/playback/once/v1/hls/v4/clear/3812193411001/aa139a8d-aa86-440e-85f8-626ce9478a01/43e3da29-be80-49d6-97c4-f49b2a7bbc2f/22783553-0b13-4fce-ba56-a07bcd294dc8/content.m3u8?bc_token=NjAyZjE1YTlfOTY5YWM4MTI4YmVhOTNlODIzZmYxMjMzZDk1MmEzMThlOTMwY2EyZmM4NWUyYjIyYmNiM2E2Njk4YmYwYTJlMg%3D%3D"
              light = {mediaImg2x}
              volume={1}
            /> */}
          </Animate>
        </Col>
        <Col xs={12} sm={8} md={5} lg={3}>
          <div class="Media-Content">
            <Animate class="Media-Title" effect={textEffect}>
            The new-age CV shaking up employment
            </Animate>
            <Animate class="Media-Link" effect={textEffect}>
            <a target="_blank" href="https://www.newshub.co.nz/home/lifestyle/2021/02/the-new-age-cv-shaking-up-employment.html">newshub.co.nz</a> 
            </Animate>
            <Animate class="Media-Text" effect={textEffect}>
            Are you an employer seeing hundreds of plain paper CVs sliding across your desk each day? Or are you a potential employee looking for a way to stand out?  …
            </Animate>
            <Animate class="Media-Date" effect={textEffect}>
            10/02/2021
            </Animate>
          </div>
        </Col>
      </Row>
    </section>
  )
}