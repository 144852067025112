import {h, Fragment} from 'preact'
import {useState} from 'preact/hooks'
import cn from 'classnames'
import SVG from 'react-inlinesvg'
import {CSSTransition} from 'react-transition-group'
import {Link} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

import './Faq.scss'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import arrowIcon from '../assets/icons/arrow.svg'
import {DURATION, ROUTE, BREAKPOINT} from '../utils/constants'

const transitionClassNames = {
  enter: 'AnswerEnter',
  enterActive: 'AnswerEnter_active',
  exit: 'AnswerExit',
  exitActive: 'AnswerExit_active',
}

const timeout = {
  enter: DURATION.standard,
  exit: DURATION.shortest,
}

const questions = [
  {
    title: 'How do I update my  digital resume info?',
    text:
      'You can update or change the details by opening the PRESENT.ME app on your phone, and selecting "Update my digital resume info". Once there, by clicking on the ≡ symbol in the top left hand corner, you can select which part you want to edit (any pre-populated questions or original answers should be displayed) and then edit your answers to update any info you need. You can select “back” and “next” to get to the question you need to update.',
  },
  {
    title: 'How do I update my video for different roles?',
    text:
      'Open the Present.me app on your phone, select “Change Video”. Here you can rewrite your script, once done, click “Next”. Select the + symbol to open the teleprompter to record a new video with your new script. Once happy with it, click “save”. From here, select which video you would like to be used on your profile by clicking “Use for my profile”.',
  },
  {
    title: 'Can I save more than one video on the teleprompter app?',
    text:
      'Yes, you can save multiple videos on the app and re-use any of your pre-recorded videos if you want to.',
  },
  {
    title:
      'Can I use the teleprompter app to make personal videos for saving and sharing from my phone without uploading to my online profile?',
    text:
      "Yes, your videos will be saved as separate files on your phone, once recorded through the app they will be accessible in your phone's video storage or gallery.",
  },
  {
    title: 'How to change my photo/ phone number/ contact details etc',
    text:
      'Open the PRESENT.ME app on your phone. Click on “Edit” under your photo to change your personal details.',
  },
  {
    title:
      'How do I share my profile with companies when applying for A JOB? (a job not visible)',
    text: (
      <>
        There are two options for this:
        <br />
        1. The easiest option is to view your online profile, scroll to the
        bottom of the page and select “Download PDF File”. This will save a PDF
        version to your phone, from where you can attach it to emails etc like
        you would a regular resume. Note: If you share it as a PDF they can
        still watch the video.
        <br />
        2. Alternatively, you can open your profile, and copy and paste the URL
        at the top of the page. Once saved you can send this link via text or
        message, or by pasting it in an email to the viewer.
      </>
    ),
  },
  {
    title:
      'Where can I get help if I am having trouble or have questions about my digital resume or apps?',
    text:
      'To get hold of support or for any help, you can email us at support@present.me.',
  },
  {
    title: 'Where can I place any feedback I have on Present.me?',
    text: 'Please email us your feedback to support@present.me.',
  },
  {
    title:
      'What do I do if I’ve lost the link to my Present.me online profile?',
    text: 'Reach out to us at support@present.me.',
  },
]

const Question = ({title, text}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen((open) => !open)

  return (
    <li class="Faq-Item">
      <h3>
        <button class="Faq-ItemTitle" onClick={handleOpen}>
          {title}
          <SVG
            class={cn('Faq-Icon', open && 'Faq-Icon_rotated')}
            src={arrowIcon}
          />
        </button>
      </h3>
      <CSSTransition
        unmountOnExit
        in={open}
        classNames={transitionClassNames}
        timeout={timeout}
      >
        <p class="Faq-Text">{text}</p>
      </CSSTransition>
    </li>
  )
}

export default function Faq() {
  const tablet = useMediaQuery({
    query: BREAKPOINT.tablet,
  })

  return (
    <main>
      <section class="Faq Container">
        <Row>
          <Col xs={12} md={4} lgOffset={1} lg={3}>
            <Animate
              class="Faq-Block"
              effect={tablet ? EFFECT.bottom : EFFECT.left}
            >
              <h2 class="Faq-Title Title">
                <Link className="Faq-Link" to={ROUTE.home}>
                  Home
                </Link>{' '}
                / <span class="Faq-Page">FAQ’s</span>
              </h2>
              <p class="Faq-Subtitle Subtitle Subtitle_smCenter">
                FAQs for Present.me
              </p>
              <p class="Faq-Text">
                If you have any other questions just email us at
                support@present.me.
              </p>
            </Animate>
          </Col>
          <Col xs={12} mdOffset={1} md={7} lg={6}>
            <Animate
              el="ul"
              className="Faq-List"
              effect={tablet ? EFFECT.bottom : EFFECT.right}
            >
              {questions.map((question) => (
                <Question key={question.title} {...question} />
              ))}
            </Animate>
          </Col>
        </Row>
      </section>
    </main>
  )
}
