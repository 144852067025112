import {h} from 'preact'
import {useMediaQuery} from 'react-responsive'

import './Prices.scss'
import Animate, {EFFECT} from './common/Animate'
import {Row, Col} from './common/grid'
import {handleScroll} from '../utils/helpers'
import {BREAKPOINT, LINK} from '../utils/constants'

export default function Prices() {
  const mobile = useMediaQuery({
    query: BREAKPOINT.mobile,
  })

  return (
    <section id={LINK.prices} class="Prices">
      <div className="Prices-Container Container">
        <Row>
          <Col xs={12} sm={5} md={4} mdOffset={1}>
            <Animate
              className="Prices-Block"
              effect={mobile ? EFFECT.bottom : EFFECT.right}
            >
              <h2 class="Prices-Title">$50 nzd per person</h2>
              <p class="Prices-Subtitle">Till the end of February then $200 from March onwards.</p>
              <p class="Prices-Text">
              Pay once and get your digital resume available forever. Includes a printable and downloadable version of your resume.
              </p>
              <a
                class="Button Button_yellow"
                href={LINK.courseUrl}
                // href={`#${LINK.contact}`}
                // onClick={handleScroll(LINK.contact)}
              >
                Start now
              </a>
            </Animate>
          </Col>
        </Row>
      </div>
    </section>
  )
}
