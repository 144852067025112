import {h} from 'preact'
import cn from 'classnames'
import SVG from 'react-inlinesvg'

import './Footer.scss'
import {Row, Col} from './common/grid'
import Logo from './common/Logo'
import {BLANK_LINK_PROPS, LINK} from '../utils/constants'

const year = new Date().getFullYear()

const icons = {
  location: require('../assets/icons/location.svg').default,
  mail: require('../assets/icons/mail.svg').default,
  phone: require('../assets/icons/phone.svg').default,
}

const links = [
  {
    type: 'location',
    text: 'Taupo, New Zealand',
    href: LINK.location,
  },
  {
    type: 'mail',
    text: 'hello@present.me',
    href: LINK.mail,
  },
  {
    type: 'phone',
    text: '+64 27 736 4652',
    href: LINK.phone,
  },
]

const renderTerms = (second) => (
  <a
    {...BLANK_LINK_PROPS}
    class={cn('Footer-Terms', {'Footer-Terms_second': second})}
    href={LINK.terms}
  >
    Terms & Conditions
  </a>
)

export default function Footer() {
  return (
    <footer class="Footer">
      <div class="Footer-Container Container">
        <Row center="sm">
          <Col className="Footer-Block" xs={12} sm={10} md={5} lg={4}>
            <Logo second />
            {renderTerms()}
          </Col>
          <Col xs={12} sm={10} md={5} lg={4}>
            <ul className="Footer-List">
              {links.map(({type, text, href}) => (
                <li key={type} class="Footer-Item">
                  <a {...BLANK_LINK_PROPS} class="Footer-Link" href={href}>
                    <SVG src={icons[type]} />
                    {text}
                  </a>
                </li>
              ))}
            </ul>
            {renderTerms(true)}
          </Col>
        </Row>
      </div>
      <address class="Footer-Copyright">
        <p class="Footer-CopyrightText">
          Copyright © {year}. All rights reserved{' '}
          <span class="Footer-Spacer">|</span> Perfectly crafted by{' '}
          <a {...BLANK_LINK_PROPS} class="Footer-Craft" href={LINK.sevenGlyphs}>
            7 glyphs™ Ltd.
          </a>
        </p>
      </address>
    </footer>
  )
}
