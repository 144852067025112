import {h} from 'preact'
import {Swiper, SwiperSlide} from 'swiper/react'

import './Testimonials.scss'
import Animate, {EFFECT} from './common/Animate'
import {Row, Col} from './common/grid'
import {DURATION, LINK} from '../utils/constants'

const testimonials = [
  {
    title: 'highly recommend it',
    text:
      "I'm glad to be one of the first few people to take advantage of the vpm app it was a great way for me to get a good job at Hamilton City Council, when good jobs are so hard to come by. I didn't think it would lead anywhere and I was hesitant to do it. But after just going through the course and making the video. It was actually pretty easy and now I have this App for life and can use it for personal use or future job applications. Highly recommend it!",
  },
  {
    title: 'I can\'t wait to use it!',
    text:
      'What a step up from a normal CV, my profile and video look amazing and I can\'t believe I made them! The course was easy to follow and guided me through the whole process step by step. I was nervous about recording my video at first, but the teleprompter made it really easy and gave me something to focus on while speaking. I can\'t wait to apply for some jobs with my Present.me! What a great course.',
  },
  // {
  //   title: 'new way presentation 3',
  //   text:
  //     'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
  // },
  // {
  //   title: 'new way presentation 4',
  //   text:
  //     'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
  // },
]

const swiper = {
  slideToClickedSlide: true,
  preventClicksPropagation: false,
  centeredSlides: true,
  grabCursor: true,
  speed: DURATION.longer,
  spaceBetween: 72,
  wrapperTag: 'ul',
  pagination: {
    clickable: true,
  },
}

export default function Testimonials() {
  return (
    <section id={LINK.testimonials} class="Testimonials">
      <div className="Testimonials-Container Container">
        <Row>
          <Col xs={12} sm={6} smOffset={6} md={4} lg={3} lgOffset={7}>
            <h2 class="Title">Job seekers testimonials</h2>
            <Animate effect={EFFECT.bottom}>
              <Swiper {...swiper} className="Testimonials-Swiper">
                {testimonials.map(({title, text}) => (
                  <SwiperSlide key={title} tag="li">
                    <h3 class="Testimonials-Title Subtitle Subtitle_center">
                      “{title}”
                    </h3>
                    <p class="Testimonials-Text">{text}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Animate>
          </Col>
        </Row>
      </div>
    </section>
  )
}
