import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Link} from 'react-router-dom'

import './Navigation.scss'
import {handleScroll} from '../../utils/helpers'
import {LINK} from '../../utils/constants'

const links = [
  {label: 'How it works', to: LINK.about},
  {label: 'Benefits to you', to: LINK.benefits},
//  {label: 'Our clients', to: LINK.clients},
  {label: 'Prices', to: LINK.prices},
  {label: 'Testimonials', to: LINK.testimonials},
  {label: "Faq's", to: LINK.faq, router: true},
  {label: 'Contact us', to: LINK.contact},
]

Navigation.propTypes = {
  isFaq: PropTypes.bool,
  drawer: PropTypes.bool,
  onClose: PropTypes.func,
}

export default function Navigation({isFaq, drawer, onClose}) {
  return (
    <nav class={cn('Navigation', {Navigation_drawer: drawer})}>
      <ul class="Navigation-List">
        {links.map(({label, to, router}) => {
          const LinkComponent = isFaq || router ? Link : 'a'
          return (
            <li key={to}>
              <LinkComponent
                class={cn('Navigation-Link', {
                  'Navigation-Link_button Button': to === LINK.contact,
                })}
                href={isFaq || router ? undefined : `#${to}`}
                to={router ? to : isFaq ? `/#${to}` : undefined}
                onClick={
                  router
                    ? drawer
                      ? onClose
                      : undefined
                    : handleScroll(to, onClose, isFaq)
                }
              >
                {label}
              </LinkComponent>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
